












































































































































export default function (Component) {
  Component.options.__source = "src/module/master-data/views/cmp-md-attr-input/attr-input-extend.vue"
}
