




























import { Component, Prop } from 'vue-property-decorator'
import { FilterCondition, FilterType } from '@/module/graphql'
import BaseFieldFilter from '@/module/components/lolth-filter/base-field-filter'
import util from '@/d2admin/libs/util'
import { t } from 'element-ui/lib/locale'
import { getRowId } from '@/module/components/lolth-explorer/supports'

@Component
export default class CmpMdExtendEntryFilter extends BaseFieldFilter {
  @Prop() readonly modelName: number
  @Prop({ default: true }) readonly emptyOption: boolean
  @Prop() readonly customFilter?: string

  private mModelName = ''
  private mSelectedValue: any[] = []
  private mOperator = 'in'

  private operators = ['in']

  get syncSelectValue(): any[] {
    if (this.mOperator !== 'in') return null
    return this.mSelectedValue
  }

  set syncSelectValue(selection: any[]) {
    this.mSelectedValue = selection || []
    this.filterCondition.value =
      this.mSelectedValue.map(getRowId).join(',')
  }

  get syncOperator() {
    return this.mOperator
  }

  set syncOperator(operator: string) {
    this.mOperator = operator
    if (operator === 'in') {
      if (this.customFilter) {
        this.filterCondition.operator = this.customFilter
        return
      }
    }
    this.filterCondition.operator = operator
  }

  get isValid() {
    return !(!this.filterCondition.filterType ||
      !this.filterCondition.field ||
      !this.filterCondition.operator ||
      !this.filterCondition.value ||
      this.filterCondition.value.length <= 0)
  }

  created(): void {
    this.mModelName = t('model.' + this.modelName)
    this.initFilterCondition(this.filterCondition)
    this.updateFilterType(this.filterCondition.operator!)
    if (this.filterCondition.value) {
      if (this.mOperator === 'in') {
        this.syncSelectValue = this.filterCondition.value
          .split(',').map(dataId => {
            return {
              id: dataId,
              code: dataId
            }
          })
      }
    }
    if (this.emptyOption) {
      this.operators.splice(0, 0, 'empty')
    }
  }

  public initFilterCondition(filterCondition: FilterCondition) {
    if (!filterCondition.operator) this.syncOperator = 'in'
    if (!filterCondition.value) filterCondition.value = null
    util.objects.reactive(filterCondition)
  }

  public updateFilterType(operator: string) {
    if (operator === 'empty') {
      this.filterCondition.filterType = FilterType.IsNull
    } else {
      this.filterCondition.filterType = this.customFilter
        ? FilterType.Custom : FilterType.Number
    }
  }

  onOperatorChanged(operator: string) {
    this.updateFilterType(operator)
    if (this.filterCondition.value) this.internalOnFilterChanged()
  }

  clearContent() {
    this.syncOperator = 'in'
    this.filterCondition.value = null
  }
}
