export default {
  routes: {
    butler: require('@/module/butler/routes').default,
    'master-data': require('@/module/master-data/routes').default,
    'plugin-estate': require('@/module/plugin-estate/routes').default
  },
  stores: {
    'master-data': require('@/module/master-data/store').default
  },
  hooks: {
    butler: require('@/module/butler/hook').default,
    'master-data': require('@/module/master-data/hook').default
  }
}
