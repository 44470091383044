















import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator'
import { MdAttribute, MdAttributeInput, MdField } from '@/module/graphql'
import { convertAttrValueToInput, findAttribute, getMdFieldId } from '@/module/master-data/md-util'
import _ from 'lodash'
import util from '@/d2admin/libs/util'

@Component
export default class CmpMdAttrForm extends Vue {
  @Prop({ required: true }) readonly colSpan!: number
  @Prop({ default: false }) readonly readonly!: boolean
  @Prop({ required: true }) readonly mdFields: MdField[]

  @Model('change', { required: true })
  readonly attributes!: (MdAttribute | MdAttributeInput)[]

  private mErrors: any = {}

  get resolvedMdFields(): MdField[] {
    if (this.readonly) {
      // 只展示设过值的MdField
      return this.attributes.map(attr => {
        // eslint-disable-next-line eqeqeq
        const mdField = _.find(this.mdFields, mdField => mdField.id == getMdFieldId(attr))
        if (!mdField) throw new Error(`MdField ${getMdFieldId(attr)} is not found`)
        return mdField
      })
    } else {
      return this.mdFields
    }
  }

  get attrInputs(): { [mdFieldId: string]: MdAttributeInput } {
    const attrInputs: { [mdFieldId: string]: MdAttributeInput } = {}
    this.resolvedMdFields.forEach(mdField => {
      attrInputs[mdField.id] = this.getAttribute(mdField)
    })

    return attrInputs
  }

  getAttribute(mdField: MdField) {
    let attr: any = findAttribute(this.attributes, mdField.id)
    if (this.readonly) return attr

    const attrInput = attr ? convertAttrValueToInput(_.cloneDeep(attr))
      : {
        fieldId: mdField.id,
        value: !_.isNil(mdField.payload?.defaultValue) ? mdField.payload?.defaultValue : null
      }
    if (attr) {
      util.objects.replace(this.attributes,
        a => getMdFieldId(a) === mdField.id, attrInput)
    } else {
      this.attributes.push(attrInput)
    }
    return attrInput
  }

  getErrors(mdField: MdField) {
    if (!this.mErrors[mdField.id]) {
      this.mErrors[mdField.id] = []
    }
    return this.mErrors[mdField.id]
  }

  @Watch('mdFields')
  updateBindingAttributes() {
    // 移除字段对应的属性
    if (this.readonly) return
    util.objects.remove(this.attributes, attr => {
      return !_.find(this.mdFields, mdField => mdField.id === getMdFieldId(attr))
    })
  }

  public clearErrors() {
    _.values(this.mErrors).forEach(errors => {
      util.objects.clear(errors)
    })
  }

  isMdFieldReadonly(mdField: MdField) {
    return mdField.payload?.context.readonly
  }

  getFieldName(mdField: MdField) {
    return mdField.payload?.alias || mdField.name
  }

  resolvedColSpan(mdField: MdField) {
    if (mdField.dataType === '附件') return 24
    if (mdField.dataType === '文字' &&
      mdField.option?.multipleLine) return 24
    return this.colSpan
  }
}
